import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CostManagementService } from 'src/app/services/costManagement.service';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss']
})
export class AdminPanelComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();
  dataSource: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  tableLoaded = true;
  displayedColumns: string[] = ['testo', 'valore', 'quantity', 'azione'];
  currentPage = 0;
  pageSize = 10;
  pageEvent: PageEvent;
  records: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  costForm: FormGroup;
  configurationForm: FormGroup;
  type_cost = 1;
  isEdit = false;
  configurations: any[] = [];
  timeDebounce: number = 1000;

  status_type: any = 1;
  type = [
    // { label: "Nessuno", value: null, selected: true },
    { label: 'Tassa iscrizione', value: 1, selected: true },
    { label: "Materiale didattico", value: 2, selected: false }
  ];

  constructor(
    private readonly fb: FormBuilder,
    private readonly costService: CostManagementService,
    private readonly toastr: ToastrService,
    private readonly configurationService: ConfigurationService
  ) {
    this.costForm = this.fb.group({
      id: [null],
      testo_scontistica: [null],
      valore: [null],
      quantity: [null],
      type: [null],
      like_search: [null]
    });
    this.initConfigurationForm();
  }

  ngOnInit(): void {
    this.getTableData();
    this.getConfiguration();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Richiama i dati nella tabella
   */
  getTableData() {
    let params = {
      skip: this.currentPage * this.pageSize,
      take: this.pageSize,
      type: this.type_cost,
      like_search: null,
    };
    if (this.costForm.get('like_search').value) {
      params.like_search = this.costForm.get('like_search').value;
    }

    this.costService.getCostTable(params).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.dataSource.next(res.result);
      this.records.next(res.total);
    });
  }

  /**
   * Funzione che recupera i dati delle configurazioni
   */
  getConfiguration = () => {
    this.configurationService.getConfigurations().pipe(takeUntil(this.destroy$)).subscribe({
      next: (response) => {
        this.configurations = response.result;
        this.configurationForm.patchValue({
          tassaIscrizione: response.result.find(el => el.type == 1)?.price ?? null,
          materialeDidattico: response.result.find(el => el.type == 2)?.price ?? null
        }, { emitEvent: false });
      }
    });
  }

  /**
   * Si attiva al cambio del tab, torna i dati nella tabella a seconda del tipo
   * @param element 
   */
  setType(element) {
    this.type_cost = element;
    this.getTableData()
  }

  /**
   * Gestisce i dati all'apertura della modale
   * @param data 
   */
  openModal(data?: any) {
    if (data) {
      this.isEdit = true;
      this.costForm.patchValue({
        id: data.id,
        testo_scontistica: data.testo_scontistica,
        valore: data.valore,
        quantity: data.quantity,
        type: data.type
      });
    } else {
      this.isEdit = false;
      this.costForm.reset();
    }
  }

  /**
   * Setta i dati della modale Delete
   * @param data 
   */
  setModaldelete(data) {
    this.costForm.patchValue({
      id: data.id,
      testo_scontistica: data.testo_scontistica,
      valore: data.valore,
      quantity: data.quantity,
      type: data.type
    });
  }

  /**
   * Gestisce il salvataggio e la modifica dei dati
   */
  saveCost() {
    if (this.isEdit) {
      this.costService.updateCost(this.costForm.value.id, this.costForm.value).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        this.toastr.success(res.message);
        this.getTableData();

      }, error => {
        this.toastr.error(error?.message);
      });
    } else {
      this.costService.addCost(this.costForm.value).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        this.toastr.success(res.message);
        this.getTableData();
      }, error => {
        this.toastr.error(error?.message);
      });
    }
  }

  /**
   * Richiamato nella paginazione
   * @param e 
   * @returns 
   */
  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getTableData();
    return e;
  }

  /**
   * Eliminazione dei dati
   */
  deleteElement() {
    this.costService.deleteCost(this.costForm.value.id).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.toastr.success(res.message);
      this.costForm.reset();
      this.getTableData();
    }, error => {
      this.toastr.error(error?.message);
    });
  }

  /**
   * Funzione di creazione del form control
   */
  private initConfigurationForm() {
    this.configurationForm = new FormGroup({
      tassaIscrizione: new FormControl(null),
      materialeDidattico: new FormControl(null)
    });

    this.configurationForm.get('tassaIscrizione').valueChanges.pipe(
      debounceTime(this.timeDebounce),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(tassaIscrizione => {
      let data = {
        type: 1,
        price: tassaIscrizione,
        label: this.configurations.find(el => el.type == 1)?.label ?? 'Tassa iscrizione'
      };
      let id = this.configurations.find(el => el.type == 1)?.id;

      if (!id) {
        this.configurationService.createConfiguration(data).pipe(takeUntil(this.destroy$)).subscribe({
          next: (response) => this.toastr.success(response.message)
        });
      } else {
        this.configurationService.updateConfiguration(data, id).pipe(takeUntil(this.destroy$)).subscribe({
          next: (response) => this.toastr.success(response.message)
        });
      }

    });

    this.configurationForm.get('materialeDidattico').valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(materialeDidattico => {
      let data = {
        type: 2,
        price: materialeDidattico,
        label: this.configurations.find(el => el.type == 2)?.label ?? 'Materiale didattico'
      };
      let id = this.configurations.find(el => el.type == 2)?.id;

      if (!id) {
        this.configurationService.createConfiguration(data).pipe(takeUntil(this.destroy$)).subscribe({
          next: (response) => this.toastr.success(response.message)
        });
      } else {
        this.configurationService.updateConfiguration(data, id).pipe(takeUntil(this.destroy$)).subscribe({
          next: (response) => this.toastr.success(response.message)
        });
      }

    });
  }
}
