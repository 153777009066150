import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { FormattedResponse } from '../models/formatted-response.model';
import { HttpContextConfig } from '../models/http-context-config';
import { CONFIG } from '../interceptors/token-interceptor.service';

const BASE_URL = `${environment.api_URL}`;

const config = new HttpContextConfig();
config.noSpinner = true;
const context = new HttpContext().set(CONFIG, config);

@Injectable({
    providedIn: 'root'
})
export class ClasseService {
    constructor(private http: HttpClient) { }


    addClass(data: any): Observable<FormattedResponse> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/addClass`, data);
    }

    updateClass(data: any): Observable<FormattedResponse> {
        return this.http.put<FormattedResponse>(`${BASE_URL}/updateClass`, data);
    }

    deleteClass(params: any): Observable<FormattedResponse> {
        return this.http.delete<FormattedResponse>(`${BASE_URL}/deleteClass`, { params });
    }

    getTable(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/tableClass`, { params, context });
    }

    getLevel(): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getAllLivelli`);
    }

    getLanguage(): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getAll_Lingue`);
    }

    getLangLevelTypology(): Observable<any> {
        return this.http.get<any>(`${BASE_URL}/get_lang_level_tipologyCourse`);
    }

    getClass(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getClass`, { params });
    }

    getAllCertifications(params?: any): Observable<any> {
        return this.http.get<any>(`${BASE_URL}/getAllCertifications`, { params, context });
    }

    addDocenteToClass(params?: any): Observable<FormattedResponse> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/addTeacherClass`, params);
    }

    editDocenteClass(data: any): Observable<FormattedResponse> {
        return this.http.put<FormattedResponse>(`${BASE_URL}/editTeacherClass`, data);
    }

    addStudentToClass(params?: any): Observable<FormattedResponse> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/addStudentClass`, params);
    }

    getTableStudent(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getTableStudent`, { params, context });
    }


    editClassTable(data: any): Observable<FormattedResponse> {
        return this.http.put<FormattedResponse>(`${BASE_URL}/editClassiAnagrafica`, data);
    }


    deleteStudenteToClass(params: any): Observable<FormattedResponse> {
        return this.http.delete<FormattedResponse>(`${BASE_URL}/deleteStudenteToClass`, { params });
    }


    deleteAllEventByClass(params: any): Observable<FormattedResponse> {
        return this.http.delete<FormattedResponse>(`${BASE_URL}/deleteAllEventByClass`, { params });
    }

    getRegistro(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/allRegister`, { params });
    }

    getCalendarClass(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getCalendarClass`, { params });
    }

    getSingleRegistro(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/getRegistro`, { params });
    }

    getRegistroConveration(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/allRegisterConversation`, { params });
    }


    getRegistroAnagrafica(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/allRegisterAnagrafica`, { params });
    }

    /**
     * Salvataggio dei dati della tabella del registro contenente gli studenti
     * @param data 
     * @returns 
     */
    editRegisterClass(data: any): Observable<FormattedResponse> {
        return this.http.put<FormattedResponse>(`${BASE_URL}/editRegisterClass`, data);
    }

    saveUpdateRegistroAnagrafica(params?: any): Observable<FormattedResponse> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/insertUpdateRegisterAnagrafica`, params);
    }

    DeleteRegistroAnagrafica(params?: any): Observable<FormattedResponse> {
        return this.http.delete<FormattedResponse>(`${BASE_URL}/deleteRegisterAnagrafica`, { params });
    }



    editRegistro(params?: any): Observable<FormattedResponse> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/editRegister`, params);
    }

    getClassiDocente(params?: any): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/classiDocente`, { params });
    }

    exportDataClass(params?: any, options?: { responseType: 'blob' }): Observable<any> {
        return this.http.post(`${BASE_URL}/exportStudents`, params, { responseType: 'blob' as 'json' });
    }

    exportDataRegister(params?: any, options?: { responseType: 'blob' }): Observable<any> {
        return this.http.post(`${BASE_URL}/exportRegister`, params, { responseType: 'blob' as 'json' });
    }

    /*
    exportDataClass(params?: any): Observable<any> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/exportStudents`, params);
    }
    */
}