import { Injectable } from "@angular/core";
import { HttpContextConfig } from "../models/http-context-config";
import { HttpClient, HttpContext } from "@angular/common/http";
import { CONFIG } from "../interceptors/token-interceptor.service";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { FormattedResponse } from "../models/formatted-response.model";

const BASE_URL = `${environment.api_URL}`;

const config = new HttpContextConfig();
config.noSpinner = true;
const context = new HttpContext().set(CONFIG, config);

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {
    constructor(private readonly http: HttpClient) { }

    /**
     * Funzione che ritorna tutte le configurazioni
     * @returns 
     */
    getConfigurations(): Observable<FormattedResponse> {
        return this.http.get<FormattedResponse>(`${BASE_URL}/configuration`, { context });
    }

    /**
     * Funzione di creazione di una configurazione
     * @param data 
     * @returns 
     */
    createConfiguration(data: any): Observable<FormattedResponse> {
        return this.http.post<FormattedResponse>(`${BASE_URL}/configuration`, { context, data });
    }

    /**
     * Funzione di aggiornamento dei dati
     * @param data 
     * @param idConfiguration 
     * @returns 
     */
    updateConfiguration(data: any, idConfiguration: number): Observable<FormattedResponse> {
        return this.http.put<FormattedResponse>(`${BASE_URL}/configuration/${idConfiguration}`, { context, data });
    }
}