import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { PreventiviService } from "../../services/preventivi.service";
import { Subject } from "rxjs";
import { BaseComponent } from "../../base/base.component";

@Component({
  selector: 'gestione-preventivi-contratti',
  templateUrl: './gestione-preventivi-contratti.html',
  styleUrls: ['./gestione-preventivi-contratti.scss']
})
export class GestionePreventiviContrattiComponent extends BaseComponent implements OnInit {
  private readonly destroy$: Subject<void> = new Subject<void>();

  type: string = '';
  action: string = '';

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _preventivi: PreventiviService
  ) {
    super();
  }

  ngOnInit(): void {
    this.type = this._route.snapshot.data.type;
    this.action = this._route.snapshot.data.action;
  }

  /**
   * Stampa in base alla tipologia
   */
  print() {
    let id = this._router.url.split('/')[2];
    let file_type = null;

    if (this.type == 'preventivo') file_type = 'Preventivo';
    else if (this.type == 'contratto') file_type = 'Contratto';

    this._preventivi.createPdf({ id, file_type }).pipe(takeUntil(this.destroy$)).subscribe({
      next: response => this.createOpenPdf(response)
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  goBack() {
    this._router.navigate(['/preventivi']);
  }
}
