import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AnagraficaService } from 'src/app/services/anagraphic.service';
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment/moment";

@Component({
  selector: 'app-calendar-conversation',
  templateUrl: './calendar-conversation.component.html',
  styleUrls: ['./calendar-conversation.component.scss']
})
export class CalendarConversationComponent implements OnInit, OnDestroy {
  @ViewChild('editModal') editModal: TemplateRef<any>;
  @ViewChild('confirmModal') confirmModal: TemplateRef<any>;

  messageControl: FormControl = new FormControl(null);
  messageForm: FormGroup = this.initMessageForm();
  filters: FormGroup = this.initFiltersForm();
  messages: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  user_id = localStorage.getItem('user_id');
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly _anagraficaService: AnagraficaService,
    public router: Router,
    private readonly dateAdapter: DateAdapter<Date>,
    private readonly toastr: ToastrService,
    public dialog: MatDialog
  ) {
    this.dateAdapter.setLocale('it-IT');
  }

  ngOnInit(): void {
    this.getMessages();

    this.filters.valueChanges.pipe(debounceTime(1000)).subscribe(() => {
      if (this.filters.get('date').value) {
        this.filters.patchValue({ date: moment(this.filters.get('date').value).format("YYYY-MM-DD") }, { emitEvent: false });
      }

      this.getMessages();
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Inizializzazione del form per la modifica
   * @private
   */
  private initMessageForm() {
    return new FormGroup({
      id: new FormControl(),
      message: new FormControl()
    });
  }

  /**
   * Inizializzazione del form per la modifica
   * @private
   */
  private initFiltersForm() {
    return new FormGroup({
      date: new FormControl(),
      string: new FormControl()
    });
  }

  /**
   * Funzione che recupera i messaggi
   */
  getMessages() {
    this._anagraficaService.getMessage(this.filters.value).pipe(takeUntil(this.destroy$)).subscribe({
      next: response => this.messages.next(response.result)
    });
  }

  /**
   * Funzione di aggiunta del messaggio
   */
  addMessage() {
    this._anagraficaService.insertMessage(this.messageControl.value).pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        if (response.result) {
          this.successResponse(response.message);
        }
      }
    })
  }

  /**
   * Funzione di modifica del flag
   * @param id
   * @param flag
   */
  editFlag(id: number, flag: boolean) {
    this.patchMessage(id, { flag });
  }

  /**
   * Funzione di modifica del messaggio
   */
  editMessage() {
    this.patchMessage(this.messageForm.get('id').value, { messaggio: this.messageForm.get('message').value });
  }

  /**
   * Modifica i parametri passati
   * @param id
   * @param params
   * @private
   */
  private patchMessage(id: number, params: any) {
    this._anagraficaService.patchMessage(id, params).pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        this.successResponse(response.message);
      }
    });
  }

  /**
   * Azioni da effettuare al successo di un'operazione
   * @param message
   * @private
   */
  private successResponse(message: string) {
    this.dialog.closeAll();
    this.toastr.success(message);
    this.messageControl.reset();
    this.messageForm.reset();
    this.getMessages();
  }

  /**
   * Funzione di eliminazione del messaggio
   */
  deleteMessage() {
    this._anagraficaService.deleteMessage(this.messageForm.get('id').value).pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        this.successResponse(response.message);
      }
    })
  }

  /**
   * Apertura della modale di modifica
   * @param message
   */
  onEditMessage(message: any) {
    this.messageForm.setValue({
      id: message.id,
      message: message.messaggio
    });

    this.dialog.open(this.editModal, {
      height: 'auto',
      width: '40vw'
    });
  }

  /**
   * Apertura della modale di conferma eliminazione
   * @param id
   */
  onDeletetMessage(id: number) {
    this.messageForm.patchValue({
      id: id
    });

    this.dialog.open(this.confirmModal, {
      height: 'auto',
      width: '30rem'
    });
  }
}