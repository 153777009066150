import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { AnagraficaService } from 'src/app/services/anagraphic.service';
import { ClasseService } from 'src/app/services/class.service';
import { isAdminOrStaff } from 'src/app/utilities/users-utilities';
import { RegistroLezioniComponent } from '../registro-lezioni/registro-lezioni.component';
import { MoodleIntegrationService } from 'src/app/services/moodle-integration.service';

@Component({
  selector: 'app-classi-details',
  templateUrl: './classi-details.component.html',
  styleUrls: ['./classi-details.component.scss']
})
export class ClassiDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild(RegistroLezioniComponent) registroLezioniComponent: RegistroLezioniComponent;

  adminOrStaff = isAdminOrStaff();
  items = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'];
  expandedIndex = 0;
  data: any;
  class_id: any;
  class_calendar: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  docente_id: any;
  class_details: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  hours: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  registro: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  tutti = { id: 0, name: 'Tutti', surname: '' };
  array_stato_studente = [
    { id: 'In corso', name: 'In corso' },
    { id: 'Ritirato', name: 'Ritirato' }
  ];
  array_preparazione_esame = [
    { id: 'Si', name: 'Si' },
    { id: 'No', name: 'No' }
  ];

  displayedColumns: string[] = ['name_surname', 'stato_studente', 'data_iscrizione', 'certificazione', 'note', 'azione'];
  array_giorni = [{
    it: "Lunedi'", en: "Monday"
  }, {
    it: "Martedi'", en: "Tuesday"
  }, {
    it: "Mercoledi'", en: "Wednesday"
  }, {
    it: "Giovedi'", en: "Thursday"
  }, {
    it: "Venerdi'", en: "Friday"
  }, {
    it: "Sabato'", en: "Saturday"
  }]
  currentPage = 0;
  pageSize = 10;
  records: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  pageEvent: PageEvent;
  students: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  id_class_docente: null;
  visualizzaStudenti = false;
  studentis: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  classes: any;
  filter: any;
  formAddDocente: FormGroup;
  formEditDocente: FormGroup;
  formAddStudente: FormGroup;
  formEditStudente: FormGroup;
  studenti_array: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  docente_array: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  user_id: string;
  ruolo: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  deleteContent: any;
  events_register: any;
  item: any;
  status_student: any = null;

  status_students = [
    { label: 'Tutti', value: null, selected: true },
    { label: 'Ritirato', value: 'Ritirato', selected: false },
    { label: 'In corso', value: 'In corso', selected: false },
  ];

  order_date: 'asc' | 'desc' = 'asc';

  // * Moodle integration variables
  public courses: any[] = [];
  public currentUser: any;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly _class: ClasseService,
    private readonly fb: FormBuilder,
    private readonly _anagraficaService: AnagraficaService,
    private readonly _classeService: ClasseService,
    private readonly toastr: ToastrService,
    private readonly router: Router,
    private readonly dateAdapter: DateAdapter<Date>,
    private readonly _moodleIntegration: MoodleIntegrationService
  ) {
    super();

    this.dateAdapter.setLocale('it-IT');
    this.formAddDocente = this.fb.group({
      classe_id: null,
      docente_id: [null, Validators.required],
    });
    this.formEditDocente = this.fb.group({
      id: null,
      classe_id: null,
      docente_id: [null, Validators.required],
    });
    this.formAddStudente = this.fb.group({
      classe_id: null,
      studente_id: [null, Validators.required],
      search_student: [null],
      stato_studente: [null],
      preparazione_esame: [null],
      note_studente: null
    });
    this.formEditStudente = this.fb.group({
      class_id: null,
      student_id: null,
      stato_studente: [null],
      preparazione_esame: [null],
      note_studente: null
    })
  }

  ngOnInit(): void {
    this.user_id = localStorage.getItem('user_id');
    this._anagraficaService.getUserId({ user_id: this.user_id }).pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.ruolo.next(res.result?.role);
    });
    this.class_id = this.router.url.split('/')[2];
    //INFO SULLA CLASSE//
    this._class.getClass({ id: this.class_id }).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.class_details.next(response.result);

      response.result.frequenza = JSON.parse(response.result.frequenza);
      this.class_details.next(response.result);
      this.students.next(response.result.studenti);
      this.id_class_docente = response.result.id_classe_anagrafica;
      this.docente_id = response.result.docente_id;
      //OTTENGO STUDENTI//
      this.getStudents()
      this.getData();
      this.getCalendarClass();
      //OTTENGO DOCENTI//
      this._anagraficaService.getDocents().pipe(takeUntil(this.destroy$)).subscribe(response => {
        this.formAddDocente.value.docente_id = response.result[0]?.id;
        this.docente_array.next(response.result);

      })
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  resetForm() {
    this.formAddStudente.reset();
  }

  getCalendarClass() {
    this._classeService.getCalendarClass({ id: this.class_id }).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.class_calendar.next(response.result);
    })
  }

  goTo(item) {
    this.router.navigate(["/registro/" + item.id])
  }

  getData() {
    let params = {
      skip: this.currentPage * this.pageSize,
      take: this.pageSize,
      classe_id: this.class_id,
      order_date: this.order_date,
      like_search: null,
      status_students_filter: null,
    };
    if (this.filter) params.like_search = this.filter;

    if (this.status_student) params.status_students_filter = this.status_student;



    this._classeService.getTableStudent(params).pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response.result) {
        this.records.next(response.total);
        this.students.next(response.result);
      }
    });
  }

  setStatusStudent(event) {
    this.status_student = event.target.value;
    this.getData();
  }

  exportToExcel(): void {
    const params = {
      table: 'students',
      classe_id: this.class_id,
      order_date: this.order_date
    };

    this._classeService.exportDataClass(params).pipe(takeUntil(this.destroy$)).subscribe(
      (response: Blob) => {
        this.downloadExcel(response);
      },
      error => {
        console.error("Errore durante l'esportazione in Excel:", error);
      }
    );
  }

  toggleOrder(column: string) {
    if (column === 'registration_date') {
      this.order_date = this.order_date === 'asc' ? 'desc' : 'asc';
      this.getData();
    }
  }

  getStudents() {
    this._anagraficaService.getStudent().pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.formAddStudente.value.studente_id = response.result[0]?.id;
      response.result.unshift(this.tutti)
      this.studenti_array.next(response.result);
    })
  }

  searchStudents() {
    let params = { like_search: this.formAddStudente.value.search_student, representatives: true };
    this._anagraficaService.searchStudents(params).pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response.result) {
        this.visualizzaStudenti = true;
      }
      this.studentis.next(response.result);
    })
  }

  setStudents(stud) {
    this.formAddStudente.patchValue({
      studente_id: stud.id,
      search_student: stud.name + " " + stud.surname
    })
    this.visualizzaStudenti = false
  }

  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getData();
    return e;
  }

  addDocente() {
    this.formAddDocente.value.classe_id = this.class_id;
    this._classeService.addDocenteToClass(this.formAddDocente.value).pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response.result) {
        this.formAddDocente.reset();
        this.toastr.success("Docente Aggiunto con successo");
        this.ngOnInit();
      }
    }, error => {
      this.toastr.error(error.error?.message);
    })
  }

  setDocente() {
    this.formEditDocente.patchValue({
      id: this.id_class_docente,
      classe_id: this.class_id,
      docente_id: this.docente_id
    })
  }

  editDocente() {
    this.formEditDocente.value.classe_id = this.class_id;
    this.formEditDocente.value.id = this.id_class_docente;

    this._classeService.editDocenteClass(this.formEditDocente.value).pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response.result) {
        this.formEditDocente.reset();
        this.toastr.success("Docente aggiornato con successo");
        this.ngOnInit();
      }
    }, error => {
      this.toastr.error(error.error.message);
    })
  }

  addStudent() {
    this.formAddStudente.value.classe_id = this.class_id;
    if (this.formAddStudente.value.studente_id == 0) {
      this.toastr.error("Inserisci uno studente valido");
    } else {
      this._classeService.addStudentToClass(this.formAddStudente.value).pipe(takeUntil(this.destroy$)).subscribe(response => {
        this.formAddStudente.reset();
        this.toastr.success("Studente Aggiunto con successo");
        this.ngOnInit();
        if (this.registroLezioniComponent) {
          this.registroLezioniComponent.getRegister();
        }
      }, error => {
        this.toastr.error(error.message);
      })
    }

  }

  setEdit(classe: any) {
    this.formEditStudente.patchValue({
      class_id: classe.classe_id,
      student_id: classe.studente_id,
      stato_studente: classe.stato_studente,
      preparazione_esame: classe.preparazione_esame,
      note_studente: classe.note_studente,
    });
  }

  editClassTable() {
    this._classeService.editClassTable(this.formEditStudente.value).pipe(takeUntil(this.destroy$)).subscribe(
      response => {
        if (response.result) {
          //METTERE IL TOST E FARE REFRESH//
          this.toastr.success('Classe aggiornata con successo');
          this.formAddStudente.reset();
          this.getData();
        }
      }
    )
  }

  gotoFunzione(element) {
    if (this.adminOrStaff) {
      this.router.navigate(['/anagrafica/info-anagrafica/' + element.studente_id]);
    }
  }

  deleteModal(element) {
    this.deleteContent = element.studenti_anagrafica;
  }

  deleteMember(id) {
    this._classeService.deleteStudenteToClass({ id: id }).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.toastr.success("Studente eliminato con successo dalla classe");
      if (this.registroLezioniComponent) {
        this.registroLezioniComponent.getRegister();
      }
      this.ngOnInit();
    })
  }

  editRegistro(event) {
    let scelta = event.target.checked;
    this._classeService.editRegistro({ id: this.item.id, presenza: scelta }).pipe(takeUntil(this.destroy$)).subscribe(response => {
      if (response.message == "Non puoi modificare una lezione non svolta") {
        this.toastr.error(response.message)
      } else {
        this.toastr.success(response.message);
      }
    })
  }

  setItemRegistro(item) {
    this.item = item;
  }

  public openEnrolUser = (element: any) => {
    this.currentUser = element;
    this._moodleIntegration.getCourses().pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => this.courses = response.result.filter((course: any) => course.categoryid === 1)
      });
  }

  public enrolUser = (course: any) => {
    this._moodleIntegration.verifyUser(this.currentUser.email).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (userVerify) => {
          if (userVerify.status) {
            this._moodleIntegration.enrolUser({ userid: userVerify.result[0].id, courseid: course.id })
              .pipe(
                takeUntil(this.destroy$),
                finalize(() => this.toastr.success('Utente iscritto con successo al corso!'))
              )
              .subscribe({
                next: () => { }
              });
          } else {
            let userData = {
              firstname: this.currentUser.name,
              lastname: this.currentUser.surname,
              email: this.currentUser.email
            };
            this._moodleIntegration.createUser(userData).pipe(takeUntil(this.destroy$))
              .subscribe({
                next: (response) => {
                  this._moodleIntegration.enrolUser({ userid: response.result[0].id, courseid: course.id })
                    .pipe(
                      takeUntil(this.destroy$),
                      finalize(() => this.toastr.success('Utente iscritto con successo al corso!'))
                    )
                    .subscribe({
                      next: () => { }
                    });
                }
              });
          }
        }
      });
  }
}