import { HttpClient, HttpContext, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CONFIG } from "../interceptors/token-interceptor.service";
import { FormattedResponse } from "../models/formatted-response.model";
import { HttpContextConfig } from "../models/http-context-config";

const BASE_URL = `${environment.api_URL}`;

const config = new HttpContextConfig();
config.noSpinner = true;
const context = new HttpContext().set(CONFIG, config);

@Injectable({
    providedIn: 'root'
})
export class MoodleIntegrationService {
    constructor(private readonly http: HttpClient) { }

    /**
     * Funzione che recupera tutti i corsi presenti su Moodle
     * @returns 
     */
    public getCourses = (): Observable<FormattedResponse> => {
        return this.http.get<FormattedResponse>(`${BASE_URL}/moodle/courses`);
    }

    /**
     * Funzione che verifica l'utente
     * @param userEmail 
     * @returns 
     */
    public verifyUser = (userEmail: string): Observable<FormattedResponse> => {
        let params: HttpParams = new HttpParams().set('email', userEmail);
        return this.http.get<FormattedResponse>(`${BASE_URL}/moodle/users`, { params });
    }

    /**
     * Funzione che mi crea gli utenti in piattaforma
     * @param data 
     * @returns 
     */
    public createUser = (data: any): Observable<FormattedResponse> => {
        return this.http.post<FormattedResponse>(`${BASE_URL}/moodle/users`, data);
    }

    /**
     * Funzione che permette di iscrivere un utente al corso
     * @param data 
     * @returns 
     */
    public enrolUser = (data: any): Observable<FormattedResponse> => {
        return this.http.post<FormattedResponse>(`${BASE_URL}/moodle/enrol`, data);
    }
}